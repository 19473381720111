import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./Redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import LogRocket from "logrocket";

ReactDOM.render(
  <ErrorBoundary
    onClick={() => {
      localStorage.clear();
      window.location.reload();
    }}
    handleComponentDidCatch={(error) => {
      LogRocket.captureMessage(error.message, {
        tags: {
          userType: "employee",
        },
        extra: {
          fatal: true,
          code: error.code,
        },
      });
    }}>
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <App />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

if (window.Cypress) {
  window.store = store;
}

reportWebVitals();
