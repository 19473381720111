import { all, put, takeEvery } from "redux-saga/effects";
import { setErrorStatus } from "../status/action";
import store from "../store";
import { isValidArray } from "../../Services/validators";
import { getFileUrl } from "../../Services/storage";
import { getPetitionAssets, setNewComplaintData } from "./action";
import { raisePetition } from "../../Services/api";

export const actionTypes = {
  PUT_PETITIONS_DATA: "PUT_PETITIONS_DATA",
  CREATE_PETITIONS: "CREATE_PETITIONS",
  PUT_SELECTED_PETITION: "PUT_SELECTED_PETITION",
  GET_PETITION_ASSETS: "GET_PETITION_ASSETS",
  SET_NEW_COMPLAINT_DATA: "SET_NEW_COMPLAINT_DATA",
};

function* setPetitionsWorker(action) {
  try {
    yield setPetitionsLoading(true);
    yield put({
      type: "SET_PETITIONS_DATA",
      payload: {
        data: action.payload.data,
      },
    });

    Object.values(action.payload.data)?.forEach((data)=>{
      getPetitionAssets(data)
    })

    yield setPetitionsLoading(false);
  } catch (error) {
    yield setPetitionsLoading(false);
    setErrorStatus(error);
  }
}
function* createPetitionsWorker(action) {
  try {
    yield setPetitionsLoading(true);
    const { proof, summary } = action.payload;

    let data = new FormData();
    const fileData = [...proof.image, ...proof.pdf];
    if (isValidArray(fileData)) {
      for (let i = 0; i < fileData.length; i++) {
        data.append("files", fileData[i]);
      }
    }
    data.append("description", summary);
    data.append(
      "issuedBy",
      JSON.stringify({
        issuedAt: +new Date(),
        userDetails: {
          name: store.getState().profile.data.aadhaar.fullName,
          phoneNumber: store.getState().auth.data.phoneNumber,
        },
      })
    );
    data.append(
      "status",
      JSON.stringify({
        currentStatus: "OPEN",
        updatedAt: +new Date(),
      })
    );

    const response = yield raisePetition(
      data,
      store.getState().auth.data.accessToken
    );

    setNewComplaintData(true, response.data.petitionId);

    yield setPetitionsLoading(false);
  } catch (error) {
    yield setPetitionsLoading(false);
    setErrorStatus(error);
  }
}

function* setSelectedPetitionWorker(action) {
  try {
    yield put({
      type: "SET_SELECTED_PETITION",
      payload: {
        petitionId: action.payload.petitionId,
      },
    });

    getPetitionAssets(action.payload.petitionId);
  } catch (error) {
    setErrorStatus(error);
  }
}

function* getPetitionAssetsWorker(action) {
  try {
    const petition = action.payload.data;

    let images = [];
    let pdfs = [];
    if (isValidArray(petition?.assets)) {
      for (let j = 0; j < petition?.assets.length; j++) {
        const file = yield getFileUrl(petition?.assets[j]);
        if (file.type === "image") {
          images.push(file.url);
        } else if (file.type === "application") {
          pdfs.push(file.url);
        }
      }

      yield put({
        type: "SET_PETITION_ASSETS",
        payload: {
          [petition.documentId]: {
            ...petition,
            fileSrc: {
              pdfs: pdfs,
              images: images,
            },
          },
        },
      });
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* setNewComplaintDataWorker(action) {
  yield put({
    type: "UPDATE_NEW_COMPLAINT_DATA",
    payload: {
      status: action.payload.status,
      petitionId: action.payload.petitionId,
    },
  });
}

export default function* petitionsWatcher() {
  yield all([
    takeEvery("PUT_PETITIONS_DATA", setPetitionsWorker),
    takeEvery("CREATE_PETITIONS", createPetitionsWorker),
    takeEvery("PUT_SELECTED_PETITION", setSelectedPetitionWorker),
    takeEvery("GET_PETITION_ASSETS", getPetitionAssetsWorker),
    takeEvery("SET_NEW_COMPLAINT_DATA", setNewComplaintDataWorker),
  ]);
}

function* setPetitionsLoading(bool) {
  yield put({
    type: "SET_PETITIONS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
