import { all, put, takeEvery } from "redux-saga/effects";
import { setErrorStatus } from "../status/action";
import { getFileUrl } from "../../Services/storage";
import { isValidObject } from "../../Services/validators";

export const actionTypes = {
  SET_ACCOUNT_PROFILES: "SET_ACCOUNT_PROFILES",
  SET_ACCOUNT_REFERRALS: "SET_ACCOUNT_REFERRALS",
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
};

function* setProfilesWorker(action) {
  try {
    yield setProfileLoading(true);

    if (!isValidObject(action.payload?.profiles)) {
      yield put({
        type: "SET_PROFILES",
        payload: {},
      });
      yield setProfileLoading(false);
      return;
    }

    const pictureSrc = yield getFileUrl(
      action.payload?.profiles?.aadhaar?.picture
    );

    yield put({
      type: "SET_PROFILES",
      payload: {
        ...action.payload.profiles,
        aadhaar: {
          ...action.payload.profiles.aadhaar,
          pictureSrc: pictureSrc?.url || null,
        },
      },
    });

    yield setProfileLoading(false);
  } catch (error) {
    yield setProfileLoading(false);
    setErrorStatus(error);
  }
}

function* setReferralsWorker(action) {
  try {
    yield setProfileLoading(true);

    if (isValidObject(action.payload.referrals)) {
      let grandTotal = 0;
      for (let i = 0; i < Object.values(action.payload.referrals).length; i++) {
        grandTotal += Object.values(action.payload.referrals)?.[i].totalCount;
      }
      yield put({
        type: "SET_REFERRALS",
        payload: {
          referrals: { ...action.payload.referrals, grandTotal: grandTotal },
        },
      });
    }
    yield setProfileLoading(false);
  } catch (error) {
    yield setProfileLoading(false);
    setErrorStatus(error);
  }
}

function* changeLanguageWorker(action) {
  try {
    yield put({
      type: "SET_LANGUAGE",
      payload: {
        language: action.payload.language,
      },
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

export default function* profileWatcher() {
  yield all([
    takeEvery("SET_ACCOUNT_PROFILES", setProfilesWorker),
    takeEvery("SET_ACCOUNT_REFERRALS", setReferralsWorker),
    takeEvery("CHANGE_LANGUAGE", changeLanguageWorker),
  ]);
}

function* setProfileLoading(bool) {
  yield put({
    type: "SET_PROFILE_LOADING",
    payload: {
      loading: bool,
    },
  });
}
