import { isValidObject } from "../../Services/validators";
import { dateAndTimeConverter } from "../../Utils/constants";
import "./Announcement.css";
import noAnnouncementFallbackIcon from "../../Assets/noAnnouncementFallbackIcon.svg";
import ConditionalRender from "../ConditionalRender/ConditionalRender";

export default function Announcement(props) {
  return (
    <>
      <div
        className="inherit-parent-height overflow-scroll"
        data-cy={`announcement-screen`}>
        <ConditionalRender condition={isValidObject(props.announcements?.data)}>
          {props.announcements?.data &&
            Object.values(props.announcements?.data)
              ?.sort((a, b) => {
                return b.createdAt - a.createdAt;
              })
              .map((data, index) => (
                <div key={index}>
                  <AnnouncementCard
                    data={data}
                    language={props.language}
                    values={data}
                    cardClick={(documentId) => {
                      props.navigate(`/announcements/${documentId}`);
                    }}
                  />
                </div>
              ))}
        </ConditionalRender>

        <ConditionalRender
          condition={!isValidObject(props.announcements?.data)}>
          <div
            className=" inherit-parent-height inherit-parent-width flex-justify-content-center flex-align-items-center flex-direction-column"
            data-cy={"no-announcements-fallback"}>
            <img src={noAnnouncementFallbackIcon} alt="Fallback icon" />

            <div className=" padding-top-larger font-family-RHD-medium font-size-larger text-align-center">
              {props.languageData.noAnnouncementPosted}
            </div>

            <div className=" font-size-medium text-align-center padding-top-default">
              {props.languageData.noAnnouncementFallbackDescription}
            </div>
          </div>
        </ConditionalRender>
      </div>
    </>
  );
}

const AnnouncementCard = (props) => {
  return (
    <div
      onClick={() => {
        props.cardClick(props.values?.documentId);
      }}
      style={{
        height: "104px",
      }}
      data-cy={`${props.data?.documentId}-announcement`}
      className="inherit-parent-width display-flex margin-bottom-large gradient-border flex-align-items-center">
      <div className="height-width-100px flex-center-children-horizontally min-width-100px overflow-hidden">
        {props.values?.postThumbnailURL?.url ? (
          <img
            className="height-100px"
            style={{
              borderRadius: "4px 0px 0px 4px",
              paddingLeft: "2px",
              objectFit: "fill",
            }}
            src={props.values?.postThumbnailURL?.url || ""}
            alt="announcement-thumbnail"
          />
        ) : (
          <div className="shimmer inherit-parent-width inherit-parent-height border-radius-default" />
        )}
      </div>
      <div className="padding-large flex-justify-content-space-between flex-direction-column">
        <div>
          <div className="font-size-medium font-family-RHD-medium">
            {truncateParagraph(props.data?.title[props.language])}
          </div>
        </div>
        <div className="display-flex flex-align-items-center font-color-secondary">
          <div>{props.data?.category[props.language]}</div>
          <div className="dot-4px margin-left-default margin-right-default" />
          <div className=" font-size-medium">
            {dateAndTimeConverter(props.values?.createdAt, "secondDate")}
          </div>
        </div>
      </div>
    </div>
  );
};

function truncateParagraph(paragraph, maxLength = 80) {
  if (paragraph.length > maxLength) {
    return paragraph.slice(0, maxLength) + "...";
  }
  return paragraph;
}
