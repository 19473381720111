import { Route, Navigate, useNavigate, Routes } from "react-router-dom";
import Login from "./Screens/Login/Login";
import Profile from "./Screens/Profile/Profile";
import { useEffect, useState } from "react";
import { useAuthSubscriber } from "./Services/authentication";
import {
  useAnnouncementsListener,
  useJobsListener,
  usePetitionsListener,
  useProfileListener,
  useReferralListener,
  useVolunteeringListener,
} from "./Services/database";
import { connect } from "react-redux";
import SnackBar from "./Components/SnackBar/SnackBar";
import Home from "./Screens/Home/Home";
import Register from "./Screens/Register/Register";
import { logout } from "./Redux/authentication/actions";
import { changeLanguage } from "./Redux/profile/action";
import SelectedPetition from "./Screens/SelectedPetition/SelectedPetition";
import CreatePetition from "./Screens/CreatePetition/CreatePetition";
import { isValidObject } from "./Services/validators";
import { logrocketInit } from "./Utils/constants";
import LogRocket from "logrocket";
import SelectedAnnouncement from "./Screens/SelectedAnnouncement/SelectedAnnouncement";
import "./App.css";
import { useLanguage } from "./Components/useLanguage/useLanguage";
import SelectedVolunteering from "./Screens/SelectedVolunteering/SelectedVolunteering";
import SelectedJob from "./Screens/SelectedJob/SelectedJob.js";
import ReferFriend from "./Screens/ReferFriend/ReferFriend.js";
import Privacy from "./Screens/Privacy/Privacy.js";

function App(props) {
  const languageData = useLanguage(props.profile.language);
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [isAuth, setIsAuth] = useState(false);

  useAuthSubscriber((isAuth) => {
    setIsAuth(isAuth);
  }, isAuth);

  useProfileListener({
    isAuth: isAuth,
    uid: props.auth.data.uid,
    phoneNumber: props.auth.data.phoneNumber,
  });

  useReferralListener({
    isAuth: isAuth,
    profileId: props.profile.data?.documentId,
    phoneNumber: props.auth.data?.phoneNumber,
  });

  usePetitionsListener({
    isAuth: isAuth,
    phoneNumber: props.auth.data.phoneNumber,
    isProfileTrue: isValidObject(props.profile.data),
  });

  useAnnouncementsListener({
    isAuth: isAuth,
    phoneNumber: props.auth.data.phoneNumber,
    isProfileTrue: isValidObject(props.profile.data),
  });

  useVolunteeringListener({
    isAuth: isAuth,
    volunteeringStatus: props.profile.data?.volunteering?.currentStatus,
  });

  useJobsListener({
    isAuth: isAuth,
    isProfileTrue: isValidObject(props.profile.data),
  });

  //for snack bar messages
  useEffect(() => {
    if (props.status.message) {
      setStatus(props.status.message[props.profile.language]);
    } else {
      setStatus(null);
    }
  }, [props.status, props.profile.language]);

  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      const release =
        typeof process.env.REACT_APP_BUILD_NUM === "string"
          ? { release: process.env.REACT_APP_BUILD_NUM }
          : {};
      if (logrocketInit.key) {
        LogRocket.init(logrocketInit.key, release);
      }
    }
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/privacy/*"
          element={
            <Privacy
              navigate={navigate}
              languageData={languageData}
              languageList={LanguagesList}
              profile={props.profile}
              changeLanguage={changeLanguage}
            />
          }
        />
        <Route
          path="/login/*"
          element={
            <PublicRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <Login
                navigate={navigate}
                languageData={languageData}
                languageList={LanguagesList}
                profile={props.profile}
                changeLanguage={changeLanguage}
              />
            </PublicRoute>
          }
        />
        <Route
          path="/register/*"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <Register
                navigate={navigate}
                languageData={languageData}
                languageList={LanguagesList}
                profile={props.profile}
                changeLanguage={changeLanguage}
                logout={props.logout}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/*"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <Home navigate={navigate} languageData={languageData} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/petitions/:petitionId/*"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <SelectedPetition
                navigate={navigate}
                languageData={languageData}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/announcements/:announcementId"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <SelectedAnnouncement
                navigate={navigate}
                languageData={languageData}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/volunteering/:volunteeringId"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <SelectedVolunteering
                navigate={navigate}
                languageData={languageData}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/jobs/:jobId"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <SelectedJob navigate={navigate} languageData={languageData} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/createPetition"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <CreatePetition navigate={navigate} languageData={languageData} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile/*"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <Profile
                navigate={navigate}
                languageData={languageData}
                languageList={LanguagesList}
                profile={props.profile}
                changeLanguage={changeLanguage}
                logout={props.logout}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/referFriend"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <ReferFriend
                navigate={navigate}
                languageData={languageData}
                languageList={LanguagesList}
                profile={props.profile}
                changeLanguage={changeLanguage}
                logout={props.logout}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/refer/:refId"
          element={
            <ReferFriend
              navigate={navigate}
              languageData={languageData}
              languageList={LanguagesList}
              profile={props.profile}
              changeLanguage={changeLanguage}
              logout={props.logout}
            />
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      {/* <img
        src={backgroundImage}
        alt="background"
        className=" inherit-parent-width position-fixed bottom-0 max-width-500px"
        style={{ zIndex: "-10", left: "50%", transform: "translateX(-50%)" }}
      /> */}

      <SnackBar
        message={status}
        status={props.status}
        type={props.status.code === null ? "success" : "error"}
      />
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    status: state.status,
    auth: state.auth,
    profile: state.profile,
  };
};

const mapDispatchToProps = function () {
  return {
    logout: () => logout(),
    changeLanguage: (language) => changeLanguage(language),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

const ProtectedRoute = ({ uid, children, profileData }) => {
  if (uid === null && profileData === null) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};

const PublicRoute = ({ uid, children, profileData }) => {
  if (uid === null && profileData === null) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

const LanguagesList = [
  { title: "English", value: "english" },
  { title: "தமிழ்", value: "tamil" },
];
