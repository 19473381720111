const profileReducer = (
  state = {
    loading: false,
    data: null,
    referrals: null,
    language: "tamil",
  },
  action
) => {
  switch (action.type) {
    case "SET_PROFILE_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_PROFILES":
      return {
        ...state,
        data: action.payload,
      };

    case "SET_REFERRALS":
      return {
        ...state,
        referrals: action.payload.referrals,
      };

    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload.language,
      };

    case "RESET":
      return {
        loading: false,
        data: null,
        referrals: null,
        language: state.language,
      };

    default:
      return state;
  }
};
export default profileReducer;
