import { all } from "redux-saga/effects";
import authWatcher from "./authentication/saga";
import profileWatcher from "./profile/saga";
import statusWatcher from "./status/saga";
import petitionsWatcher from "./petitions/saga";
import navigationWatcher from "./navigation/saga";
import announcementsWatcher from "./announcements/saga";
import volunteerWatcher from "./volunteer/saga";
import jobsWatcher from "./jobs/saga";

export default function* rootSaga() {
  yield all([
    authWatcher(),
    announcementsWatcher(),
    profileWatcher(),
    statusWatcher(),
    petitionsWatcher(),
    navigationWatcher(),
    volunteerWatcher(),
    jobsWatcher()
  ]);
}
