import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header/Header";
import "./ReferFriend.css";
import QRCode from "qrcode";
import referFriendInfoEng from "../../Assets/referFriendInfoEng.svg";
import referFriendInfoTam from "../../Assets/referFriendInfoTam.svg";
import Button from "../../Components/Button/Button";
import { DownloadIcon, ReferIcon } from "../../Assets/assets";
import arunNehruProfileImage from "../../Assets/arunNehruProfileImage.svg";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import { setReferenceId } from "../../Redux/authentication/actions";

export default function ReferFriend(props) {
  const { refId } = useParams("refId");

  const qrCodeRef = useRef(null);
  const [qrCode, setQRCode] = useState(null);

  const generateQRCode = async () => {
    try {
      const dataUrl = await QRCode.toDataURL(
        `${window.location.origin}/refer/${props.profile?.data?.documentId}`,
        {
          width: 300,
          color: {
            dark: "#FFFFFF",
            light: "#4b63cc",
          },
          small: true,
        }
      );
      setQRCode(dataUrl);
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  useEffect(() => {
    if (refId) {
      setReferenceId(refId);
      setTimeout(() => {
        props.navigate("/");
      }, 1000);
    }
    // eslint-disable-next-line
  }, [refId]);

  const downloadImage = () => {
    if (!qrCodeRef?.current || !qrCode) {
      return;
    }

    const options = {
      scale: 2, // Increase scale for better image quality
      width: 595, // A4 width in pixels
      height: 842, // A4 height in pixels
    };

    html2canvas(qrCodeRef.current, options).then((canvas) => {
      const dataUrl = canvas.toDataURL();
      const downloadLink = document.createElement("a");

      downloadLink.href = dataUrl;
      downloadLink.download = "ARUN-NEHRU-APP-QR-CODE";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };

  const handleShare = async () => {
    const data = {
      title: props.languageData.appTitle,
      text: props.languageData.shareText,
      url: `${window.location.origin}/refer/${props.profile?.data?.documentId}`,
    };
    if (
      typeof navigator.canShare === "function" &&
      typeof navigator.share === "function" &&
      navigator.canShare(data)
    ) {
      try {
        await navigator.share(data);
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  useEffect(() => {
    generateQRCode();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="inherit-parent-height inherit-parent-width flex-justify-content-center">
        {refId ? (
          <>
            <div className=" flex-align-items-center flex-justify-content-center inherit-parent-height inherit-parent-width">
              <div className="qr-loader" data-cy="qr-loader" />
            </div>
          </>
        ) : (
          <div className="max-width-500px refer-friend-bg">
            <Header
              languageData={props.languageData}
              title={props.languageData?.referFriend}
              backOnClick={() => {
                props.navigate("/");
              }}
              // languageOnClick={() => {
              //   props.navigate("/profile/languages");
              // }}
            />
            <div className="remaining-body-height inherit-parent-width overflow-scroll">
              <div className="display-flex inherit-parent-height flex-justify-content-space-between flex-direction-column">
                <div
                  style={{
                    fontSize: "24px",
                  }}
                  className="font-color-white text-align-center padding-top-large padding-left-large padding-right-large padding-bottom-default"
                >
                  {props.languageData.join}{" "}
                  <span className="font-family-RHD-medium">
                    {props.languageData.ANApp}{" "}
                  </span>
                  {props.languageData.today}
                </div>
                <div className="font-color-white text-align-center">
                  {props.languageData.referDescription}
                </div>
                <div className="padding-top-large inherit-parent-width">
                  <img
                    style={{
                      width: "100%",
                    }}
                    src={
                      props.profile.language === "tamil"
                        ? referFriendInfoTam
                        : referFriendInfoEng
                    }
                    alt="info"
                  />
                </div>
                <div className="inherit-parent-width flex-justify-content-center padding-top-large">
                  {qrCode ? (
                    <img
                      className="qr-image-border"
                      src={qrCode}
                      alt="QR CODE"
                      height={220}
                      data-cy="counter-qr-code"
                    />
                  ) : (
                    <div className="height-width-220px" />
                  )}
                </div>
                <p className="font-color-white text-align-center padding-top-large  margin-right-large margin-left-large">
                  {props.languageData.shareDescription}
                </p>
                <div className="inherit-parent-width padding-top-large padding-left-larger padding-right-larger">
                  <Button
                    data-cy="share-button"
                    type="button"
                    variant="secondary"
                    onClick={handleShare}
                    text={
                      <div
                        style={{
                          height: "20px",
                        }}
                        className="display-flex flex-justify-content-center flex-align-items-center"
                      >
                        <div
                          style={{
                            height: "18px",
                          }}
                        >
                          <ReferIcon height="18" width="18" color="#4362EA" />
                        </div>
                        <div className="padding-left-large font-color-primary">
                          {props.languageData.shareViaLink.toUpperCase()}
                        </div>
                      </div>
                    }
                    boxShadow={false}
                  />
                  <Button
                    data-cy="download-button"
                    className={`margin-top-large`}
                    type="button"
                    onClick={downloadImage}
                    text={
                      <div
                        style={{
                          height: "20px",
                        }}
                        className="display-flex flex-justify-content-center flex-align-items-center"
                      >
                        <div
                          style={{
                            height: "18px",
                          }}
                        >
                          <DownloadIcon
                            height="18"
                            width="18"
                            color="#4362EA"
                          />
                        </div>
                        <div className="padding-left-large font-color-white">
                          {props.languageData.downloadQr.toUpperCase()}
                        </div>
                      </div>
                    }
                    boxShadow={false}
                  />
                </div>
                {props.profile.referrals?.grandTotal > 0 ? (
                  <div className="font-color-white text-align-center padding-bottom-large padding-top-large font-size-medium ">{`${
                    props.languageData.referredCount
                  } ${props.profile.referrals?.grandTotal
                    ?.toString()
                    ?.padStart(2, "0")} ${props.languageData.userSoFar}`}</div>
                ) : (
                  <div className="font-color-white text-align-center padding-bottom-large padding-top-large font-size-medium">{`${props.languageData.noReferredCount}`}</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        ref={qrCodeRef}
        style={{
          left: "-9999px", // Move off-screen
          width: "595px", // A4 width
          height: "842px", // A4 height
        }}
        className="flex-direction-column flex-align-items-center flex-justify-content-center position-absolute refer-friend-bg"
      >
        <div className="display-flex flex-justify-content-center ">
          <img src={arunNehruProfileImage} alt="" />
        </div>
        <div
          style={
            props.profile.language === "tamil"
              ? { fontSize: "20px" }
              : {
                  fontSize: "32px",
                }
          }
          className="font-color-white text-align-center padding-larger"
        >
          {props.languageData.join}{" "}
          <span className="font-family-RHD-medium">
            {props.languageData.ANApp}{" "}
          </span>
          {props.languageData.today}{" "}
        </div>
        <div
          style={
            props.profile.language === "tamil"
              ? { fontSize: "10px" }
              : {
                  fontSize: "16px",
                }
          }
          className="font-color-white text-align-center padding-bottom-default"
        >
          {props.languageData.referDescription}
        </div>
        <div className="padding-top-large inherit-parent-width">
          <img
            style={{
              width: "100%",
            }}
            src={
              props.profile.language === "tamil"
                ? referFriendInfoTam
                : referFriendInfoEng
            }
            alt="info"
          />
        </div>
        <div className="inherit-parent-width flex-justify-content-center padding-top-large">
          {qrCode ? (
            <img
              className="qr-image-border"
              src={qrCode}
              alt="QR CODE"
              height={220}
              data-cy="counter-qr-code"
            />
          ) : (
            <div className="height-width-220px" />
          )}
        </div>
        <p className="font-color-white text-align-center padding-top-large padding-right-larger padding-left-larger margin-right-larger margin-left-larger">
          {props.languageData.scanQr}
        </p>
      </div>
    </>
  );
}
