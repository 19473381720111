import React, { useEffect } from "react";
import { connect } from "react-redux";
import { isValidObject } from "../../Services/validators";
import Petitions from "../../Components/Petitions/Petitions";
import ConditionalRender from "../../Components/ConditionalRender/ConditionalRender";
import Header from "../../Components/Header/Header";
import { setDisplayScreen } from "../../Redux/navigation/action";
import Jobs from "../../Components/Jobs/Jobs";
import Announcement from "../../Components/Announcement/Announcement";
import "./Home.css";
import Volunteer from "../../Components/Volunteer/Volunteer";
import { updateOpting } from "../../Redux/volunteer/action";

function Home(props) {
  useEffect(() => {
    if (
      !isValidObject(props.profile.data) ||
      props.auth.register.transactionId
    ) {
      props.navigate("/register");
    }

    // eslint-disable-next-line
  }, [props.profile.data, props.auth.register]);

  return (
    <>
      <Header
        languageData={props.languageData}
        setDisplayScreen={(screen) => {
          props.setDisplayScreen(screen);
        }}
        navigation={props.navigation}
        profileOnClick={() => {
          props.navigate("/profile");
        }}
      />
      <div
        style={{ height: "calc(100% - 104px)" }}
        className={` ${
          props.navigation.screen === "jobs" ? "background-white-half" : ""
        } flex-justify-content-space-between flex-direction-column flex-align-items-center`}>
        <div className="inherit-parent-height padding-large max-width-500px inherit-parent-width">
          <ConditionalRender
            condition={props.navigation.screen === "petitions"}>
            <Petitions
              petitions={props.petitions}
              languageData={props.languageData}
              petitionOnclick={(documentId) => {
                props.navigate(`/petitions/${documentId}`);
              }}
              newPetitionOnclick={() => {
                props.navigate("/createPetition");
              }}
            />
          </ConditionalRender>

          <ConditionalRender condition={props.navigation.screen === "jobs"}>
            <Jobs
              languageData={props.languageData}
              profile={props.profile}
              jobs={props.jobs}
              navigate={props.navigate}
            />
          </ConditionalRender>

          <ConditionalRender
            condition={props.navigation.screen === "volunteering"}>
            <Volunteer
              languageData={props.languageData}
              profile={props.profile}
              volunteer={props.volunteer}
              navigate={props.navigate}
              updateOpting={props.updateOpting}
            />
          </ConditionalRender>

          <ConditionalRender condition={props.navigation.screen === "home"}>
            <Announcement
              languageData={props.languageData}
              language={props.profile.language}
              announcements={props.announcements}
              navigate={props.navigate}
            />
          </ConditionalRender>
        </div>
      </div>

    
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    announcements: state.announcements,
    profile: state.profile,
    petitions: state.petitions,
    navigation: state.navigation,
    volunteer: state.volunteer,
    jobs: state.jobs,
  };
};

const mapDispatchToProps = function () {
  return {
    setDisplayScreen: (screen) => setDisplayScreen(screen),
    updateOpting: (optingStatus) => updateOpting(optingStatus),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
